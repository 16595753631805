import React from "react"
import Title from "../Title"
import styles from "../../css/contact.module.css"
import { emailStrings } from "./ContactStrings"
import Anilink from "gatsby-plugin-transition-link/AniLink"

const EmailContact = props => {
  return (
    <section>
      <Title title="Kontaktieren Sie uns per" subtitle="Formular!" />
      <p>
        Sie können uns direkt eine E-Mail an die Adresse{" "}
        <a href="mailto:info@paul-berenz.de">info@paul-berenz.de</a> senden.
      </p>
      <p>
        Wenn Sie unser Kontaktformular verwenden möchten, geben Sie uns bitte
        per Klick auf das Feld <i>Formular anzeigen</i> Ihr Einverständnis.
        Weitere Informationen finden Sie in unserer{" "}
        <Anilink to="/datenschutz">Datenschutzerklärung</Anilink>.
      </p>
      <div class={props.class}>
        <form
          action="https://formspree.io/mlepgznb"
          method="POST"
          className={styles.form}
        >
          <div>
            <label htmlFor="name">name</label>
            <input
              type="text"
              name="name"
              aria-label="name"
              id="name"
              className={styles.formControl}
              placeholder={emailStrings.namePlaceholder}
            />
          </div>
          <div>
            <label htmlFor="email">email</label>
            <input
              type="email"
              name="email"
              aria-label="email"
              id="email"
              className={styles.formControl}
              placeholder={emailStrings.emailPlaceholder}
            />
          </div>
          <div>
            <label htmlFor="message">Message</label>
            <textarea
              name="message"
              aria-label="message"
              id="message"
              rows="10"
              className={styles.formControl}
              placeholder={emailStrings.messagePlaceholder}
            />
          </div>
          <div>
            <input
              type="text"
              name="_gotcha"
              className={styles.formControlHidden}
            />
            <input
              aria-label="submit"
              type="submit"
              value={emailStrings.submitText}
              className={styles.submit}
            />
          </div>
        </form>
      </div>
      <button class={props.classbutton} onClick={props.function}>
        Wir benötigen Ihr Einverständnis. Wir nutzen einen Drittanbieter, um das
        Kontaktformular einzubinden. Dieser kann personenbezogene Daten über
        Ihre Aktivitäten sammeln.
        <h3>Formular anzeigen</h3>
      </button>
    </section>
  )
}

export default EmailContact
