import React, { useState } from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import "bootstrap/dist/css/bootstrap.min.css"
import styled from "styled-components"
import MapContact from "../components/Contact/MapContact"
import EmailContact from "../components/Contact/EmailContact"
import StyledHero from "../components/StyledHero"
import Title from "../components/Title"

const Index = ({ data }) => {
  const [map, setMap] = useState("hide")
  const [form, setForm] = useState("hide")
  const [mapbutton, setMapButton] = useState("show")
  const [formbutton, setFormButton] = useState("show")
  const toggleMap = function () {
    setMap("show")
    setMapButton("hide")
  }
  const toggleForm = function () {
    setForm("show")
    setFormButton("hide")
  }

  return (
    <Layout>
      <SEO title="Kontakt" description={data.site.siteMetadata.description} />
      <StyledHero img={data.contactImage.childImageSharp.fluid}></StyledHero>
      <ServicesContainer>
        <Title title="Kontakt zu uns" subtitle="" />
        <hr className="services_separator" />
        <ContactWrapper>
          <MapContact
            function={toggleMap}
            class={map}
            classbutton={mapbutton}
          ></MapContact>
          <EmailContact
            function={toggleForm}
            class={form}
            classbutton={formbutton}
          ></EmailContact>
        </ContactWrapper>
      </ServicesContainer>
    </Layout>
  )
}

export const getIndexData = graphql`
  query {
    contactImage: file(relativePath: { eq: "connectBcg.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

const ServicesContainer = styled.section`
  padding-top: 3rem;

  .services_separator_margin {
    width: 20%;
    margin: 3rem auto;
  }

  .services_separator {
    width: 20%;
    margin: 0 auto;
  }
`

const ContactWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;

  padding: 2rem;
`
export default Index
