import React from "react"
import Title from "../Title"
import styled from "styled-components"
import Anilink from "gatsby-plugin-transition-link/AniLink"

const MapContact = props => {
  return (
    <section>
      <Title title="Besuchen Sie uns" subtitle="in Cochem-Brauheck" />
      <TextWrapper>
        <strong>Anschrift:</strong> Paul Berenz Bedachungen GmbH - Industriering
        21 - 56812 Cochem-Brauheck
        <br />
        <strong>Kontakt:</strong> Tel.: +49 (0) 26 71 - 48 08
        <br />
        <strong>Fax:</strong> +49 (0) 26 71 - 42 73
        <br />
        <strong>E-Mail:</strong> info@paul-berenz.de
        <br />
      </TextWrapper>
      <p>
        Um unseren Standort auf einer GoogleMaps Karte einzusehen, geben Sie uns
        bitte per Klick auf das Feld <i>Karte anzeigen</i> Ihr Einverständnis.
        Weitere Informationen finden Sie in unserer{" "}
        <Anilink to="/datenschutz">Datenschutzerklärung</Anilink>.
      </p>
      <MapWrapper>
      { props.class == "show" &&
        <iframe
          class={props.class}
          title="change-maps"
          frameBorder="0"
          width="100%"
          height="350px"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          src="https://maps.google.com/maps?q=Paul%20Berenz%20Cochem-Brauheck%20Industriering%2021&amp;t=m&amp;z=15&amp;output=embed&amp;iwloc=near"
          aria-label="Paul Berenz, Industriering 21, Cochem-Brauheck"
        ></iframe>
        }
      </MapWrapper>
      <button class={props.classbutton} onClick={props.function}>
        Wir benötigen Ihr Einverständnis. Wir nutzen einen Drittanbieter, um
        eine Landkarte einzubinden. Dieser kann personenbezogene Daten über Ihre
        Aktivitäten sammeln.
        <h3>Karte anzeigen</h3>
      </button>
    </section>
  )
}

const MapWrapper = styled.div`
  box-shadow: var(--lightShadow);
  transition: var(--mainTransition);

  &:hover {
    box-shadow: var(--darkShadow);
  }
`
const TextWrapper = styled.div`
  padding: 0.5rem;

  @media (min-width: 780px) {
    padding: 2rem;
  }
`
export default MapContact
